/** 
 * 视听云---首页
 */
<template>
  <v-card flat>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerAudioVisualCloud4001(1).jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <!-- <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="banner-title font-size-60 mb-6 pa-0 font-weight-bold justify-center text-align-center">
                  CITV视听平台合规认证，<br/>助力直播、点播业务 
                </v-card-title>
                <v-card-title class="banner-text justify-center px-8 pa-0">
                  简单易用，视听业务更安全合规，让企业安心开展直播、点播业务
                </v-card-title>
                <div class="btnWrap">
                    <p class="banner-btn">立即咨询</p>
                </div>
              </div>
            </div>
          </v-card>
        </v-container> -->
      </v-img>
    </v-banner>
    <!-- 详细内容 -->
    <v-container class="containerStyle">
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div>
          <img src="https://h5.ophyer.cn/official_website/other/audio01.png" />
        </div>
        <div class="text-wrap align-center">
          <v-card flat style="padding-left: 160px;">
            <v-card-title class="con_tit pa-0" >开通简单，操作方便 <br/>轻松开展云视听业务</v-card-title>
            <v-card-text class="con_text pa-0">企业现有云空间绑定CITV.CN子域名，即可安全合规从事视听业务</v-card-text>
            <v-card-actions class="pa-0">
              <v-btn 
                rounded-2 
                class="banner-btn"
                target="_blank"
                @click="handleAppTarget()" 
              >
                立即申请
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center " flat>
        <div>
          <img src="https://h5.ophyer.cn/official_website/other/audio02.png" />
        </div>
        <div class="text-wrap">
          <v-card flat style="padding-left: 72px;padding-right:175px;">
            <v-card-title class="con_tit pa-0" >全牌照的网络视频服务商</v-card-title>
            <v-card-text class="con_text pa-0">符合中国政府规定的审核机制和能力，拥有《信息网络传播视听节目许可证》</v-card-text>
            <v-card-actions class="pa-0">
              <v-btn rounded-2 class="banner-btn" @click="handleAppTarget()" >
                立即申请
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
      <v-card class="part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div>
          <img src="https://h5.ophyer.cn/official_website/other/audio03.png" />
        </div>
        <div class="text-wrap">
          <v-card flat style="padding-left: 160px;">
            <v-card-title class="con_tit pa-0" >针对不同客户提供多种解决方案</v-card-title>
            <v-card-text class="con_text pa-0">存储空间认证服务+UGC伙伴计划+融媒体资讯及构建服务</v-card-text>
            <v-card-actions class="pa-0">
              <v-btn rounded-2 class="banner-btn" @click="handleAppTarget()" >
                立即申请
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
    </v-container>
    <!-- 应用场景 -->
    <v-card class="warp warp4 mb-6" :flat="true">
      <div class="public-title">
        <p>APPLICATION SCENARIO</p>
        <div>
          <span>应用场景广泛</span>
          <span>完全稳定的技术支撑，满足多场景视听合规需求</span>
        </div>
      </div>
      <div class="con mb-15">
        <v-row>
          <v-col
            v-for="(n,m) in cards" :key="m" cols="3"
            class="d-flex child-flex"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div>
                {{ n.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-card>
</template>

<script>
let _this;
export default {
  name: "AudioVisualCloud",
  data: () => ({
    src:require("../../../public/img/more-bac.png"),
    cards: [
      { id:1, src: 'https://h5.ophyer.cn/official_website/other/scene1.png' ,title:'直播、点播场景'},
      { id:2,  src: 'https://h5.ophyer.cn/official_website/other/scene2.png' ,title:'视频购物场景' },
      { id:3,  src: 'https://h5.ophyer.cn/official_website/other/scene3.png'  ,title:'VR/AR场景'},
      { id:4,  src: 'https://h5.ophyer.cn/official_website/other/scene4.png' ,title:'在线教育场景' },
    ],
      bannerLoad: false,
      imgLoad: false,
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    handleAppTarget(){
      const routeapp = '/audiovisualCloud/Application'
      window.open(window.location.protocol + "//" + window.location.host + routeapp)
    }
  }
}
</script>

<style lang="scss" scoped>
.public-title{
  margin-bottom: 0px;
  height: unset;
}
.containerStyle{
  margin-top: 50px;
  .part-one{
    margin-bottom: 21px;
    img{
      border-radius: 10px;
      width: 600px;
      height: 300px;
      object-fit: contain;
    }
    .text-wrap{
      width: 600px;
      align-items: center;
      display: flex;
      .con_tit{
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        line-height: 34px;
        margin-top: 40px;
      }
      .con_text{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        height: unset;
        margin-top: 16px;
      }
      .banner-btn {
        margin-top: 44px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.warp {
  max-width: 1200px;
  margin: 0 auto;
}

.warp4 {
  .con {
    margin-top: 45px;
    .box-img {
      width: 240px;
      height: 160px;
      border-radius: 10px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

::v-deep .theme--light.v-sheet{
  background-color:unset
}
// .more_container{
//   height: 120px;
//   margin-top: 60px;
//   .more_left{
//     margin-right: 180px;
//     .more-tit{
//       font-size: 30px;
//       font-weight: 400;
//       color: #333333;
//       line-height: 13px;
//     }
//   }
//   .more_right{
//     display: flex;
//     align-items: center;
//     .qr_img{
//       img{
//         border-radius: 5px;
//         margin-right: 20px;
//         width: 80px;
//         height: 80px;
//       }
//     }
//     .textStyle{
//       height: 55px;
//       font-size: 14px;
//       //font-family: SourceHanSansCN-Regular;
//       font-weight: 400;
//       color: #333333;
//       line-height: 20px;
//     }
//   }
// }



</style>